import pickle1 from '../src/source/1.webp'
import pickle3 from '../src/source/3.webp'

import mainpagegif from '../src/source/mainpage.webp'
import mainpageleft from '../src/source/leftside.webp'

import tornado from '../src/source/tornado.webp'

import opensea from '../src/source/opensea.png'
import zonic from '../src/source/zonicapp.png'
import twitter from '../src/source/twitter.png'
import discord from '../src/source/dc.png'

import zonic1 from '../src/source/zonicapp2.png'
import opensea1 from '../src/source/openseab.png'

import l2storm from '../src/source/l2beat.webp'
import attention from '../src/source/attention.webp'
import lovers from '../src/source/lovers.webp'

import guild from '../src/source/guild.webp'
import room from '../src/source/room.webp'

import arrow from '../src/source/arrow.png'

import akerems from '../src/source/team/akerems.webp'
import mizaru from '../src/source/team/mizaru.webp'
import chaku from '../src/source/team/chaku.webp'
import lucas from '../src/source/team/lucas.webp'
import peculiar from '../src/source/team/peculiar.webp'

import rocketpicklez from '../src/source/rocketpicklez.png'



import { useState } from 'react'

import { Link } from 'react-scroll'

export default function Home() {

const [open, setOpen] = useState(false);

function DropdownItem(){
  return(
    <div className='bg-pickle-green mt-1 rounded-3xl p-2 text-left border border-black'>
      <Link to="supdude" offset={-80} smooth={true} duration={500}><li class=' hover:text-gray-300 cursor-pointer rounded-3xl ease-in duration-50 '>Home</li></Link>
      <Link to="buy" offset={-10} smooth={true} duration={500}><li class=' hover:text-gray-300 cursor-pointer rounded-3xl ease-in duration-50 '>Buy</li></Link>
      <Link to="vision" offset={-100} smooth={true} duration={500}><li class=' hover:text-gray-300 cursor-pointer rounded-3xl ease-in duration-50 '>Vision</li></Link>
      <Link to="community" offset={-70} smooth={true} duration={500}><li class=' hover:text-gray-300 cursor-pointer rounded-3xl ease-in duration-50 '>Community</li></Link>
      <Link to="team" offset={-50} smooth={true} duration={500}><li class=' hover:text-gray-300 cursor-pointer rounded-3xl ease-in duration-50 '>Team</li></Link>
    </div>)
}




return (
    <main class='font-poppins'>

      
        <title>Tickled Picklez</title>
      

        <nav id="navbar" class='sticky z-10 top-0 flex text-white min-w-full font-bold'>

          <div  id="desktopnav" class='flex bg-pickle-purple border h-9v border-black w-100v max-h-max my-auto'>

              <ul class='flex mx-auto max-w-max gap-6 xsm:gap-12 xl:gap-24 tracking-wider max-h-max my-auto'>

                <Link to="supdude" offset={-80} smooth={true} duration={500}><li class='hover:scale-[115%] hover:text-gray-300 cursor-pointer rounded-3xl ease-in duration-50'>Home</li></Link>
                <Link to="buy" offset={-70} smooth={true} duration={500}><li class='hover:scale-[115%] hover:text-gray-300 cursor-pointer rounded-3xl ease-in duration-50'>Buy</li></Link>
                <Link to="vision" offset={-100} smooth={true} duration={500}><li class='hover:scale-[115%] hover:text-gray-300 cursor-pointer rounded-3xl ease-in duration-50'>Vision</li></Link>
                <Link to="community" offset={-120} smooth={true} duration={500}><li class='hover:scale-[115%] hover:text-gray-300 cursor-pointer rounded-3xl ease-in duration-50'>Community</li></Link>
                <Link to="team" offset={-50} smooth={true} duration={500}><li class='hover:scale-[115%] hover:text-gray-300 cursor-pointer rounded-3xl ease-in duration-50'>Team</li></Link>

              </ul>
          </div>

          <div className='absolute mt-4 right-0' id="dropdowntext">

            <div>
            <div className='mr-auto'>
                <button id="navbutton" 

                    class='text-white bg-pickle-redpurple right-4 flex ml-auto
                    hover:bg-pickle-redpurple px-2 mr-2
                    rounded-2xl font-bold
                    border-black tracking-wider' onClick={()=>{setOpen(!open)}}>
                      Menu

                </button>
              </div>
              <ul className={`dropdownmenu ${open? "active" : "inactive"}`}>
                <DropdownItem/>
              </ul>

              </div>

          </div>
    
          

        </nav>
        
        <section id='supdude' class='w-full mt-16 xsm1:mt-0 max-h-screen'>

          <div class=''>
          <img alt="Tickled Picklez NFT Optimism" class="absolute right-0 w-[45vw] z-1 xsm:top-[15%] max-h-max" id="mainpageleft" src={mainpageleft}></img>
            <h1 id="stroke" class=' text-white mb-10 tracking-wider font-bold'>Tickled Picklez</h1>
          
            
                <a href='https://www.rocket.tickledpicklez.fun'>

                  <button id="gotomint" 
                  
                  class='text-white bg-pickle-blue 

                  hover:bg-pickle-darkblue
                  
                  max-w-max w-auto rounded-2xl font-bold

                  border-black tracking-wider'>
                    GO TO GAME
                  </button>
                </a>

                
            

          </div>

          <div class='xsm:flex w-full'>

              <div class='mx-auto max-w-max'>

                  <div>

                  <img alt="Tickled Picklez NFT Optimism" class="absolute w-15v xmb:w-10v left-0" src={pickle1}></img>

                    <p id="stroke" class='xsm:absolute w-[60%] xsm:w-[33vw] mb-8 xsm:mb-0 max-w-max mx-auto leading-normal rounded-3xl text-center text-white tracking-widest font-bold '> 
                      
                      <span class='text-pickle-red'> K</span>
                      <span class='text-pickle-orange'>A</span>
                      <span class='text-pickle-yellow'>W</span>
                      <span class='text-pickle-green'>A</span>
                      <span class='text-pickle-blue'>I</span>
                      <span class='text-pickle-redpurple'>I </span>
                      <span class='text-pickle-green'>Pickle</span> NFT collection
                      on <span class='bg-pickle-red border border-black p-4 rounded-3xl'>Optimism</span> 
                    </p>
                </div>

              </div>
              
              <div class='flex max-w-screen mx-auto'>

               
                  <img alt="Tickled Picklez NFT Optimism" class="rounded-3xl xsm:w-25v sm:w-20v mt-6 xsm:mt-0 xsm:ml-16 sm:ml-0 xmb:w-30 w-50v border-gray-600 border max-w-max mx-auto" id="imgtrf" src={mainpagegif}></img>
               

                
                  <ul class='absolute xsm:relative top-[20%] xsm:top-[10%] right-[5%] xsm:right-[10%]' id="ultrf">

                    <a href='https://opensea.io/collection/tickledpicklez' target='_blank' rel="noreferrer"><img alt="Tickled Picklez NFT Optimism" id="litrf" class="w-13v xsm1:w-4v xmb:w-5v pb-2 xsm1:pb-3" src={opensea}></img></a>
                    <a href='https://zonic.app/collection/tickledpicklez' target='_blank' rel="noreferrer"><img alt="Tickled Picklez NFT Optimism" id="litrf" class="w-13v xsm1:w-4v xmb:w-5v pb-2 xsm1:pb-3" src={zonic}></img></a>
                    <a href='https://discord.com/invite/YBsBJqxjXx' target='_blank' rel="noreferrer"><img alt="Tickled Picklez NFT Optimism" id="litrf" class="w-13v xsm1:w-4v xmb:w-5v pb-2 xsm1:pb-3" src={discord}></img></a>
                    <a href='https://twitter.com/TickledPicklez' target='_blank' rel="noreferrer"><img alt="Tickled Picklez NFT Optimism" id="litrf" class="w-13v xsm1:w-4v xmb:w-5v" src={twitter}></img></a>

                  </ul>
                
              </div>
       
          </div>
          
        </section>

        <section id='buy'>
            <div class='xsm:flex text-center h-70v max-w-max mx-auto'>

            <div class='max-h-max my-auto'>

            <h4 id="stroke" class='z-2 leading-snug text-white rounded-2xl tracking-wider w-[100%] max-w-max mx-auto xsm:w-[45vw] max-h-max my-auto font-bold'>
            <span id="highlight1" class='bg-pickle-darkblue border border-black rounded-3xl px-5'> MINT OUT </span><br/>
            You can buy on <br/>
            <div class="max-w-max mx-auto">

            <a href='https://opensea.io/collection/tickledpicklez' target='_blank' rel="noreferrer">
              <img alt="Tickled Picklez NFT Optimism" class="max-w-max mx-auto mb-6 w-60v xsm:w-30v" src={opensea1}></img></a>

            <a href='https://zonic.app/collection/tickledpicklez' target='_blank' rel="noreferrer">
              <img alt="Tickled Picklez NFT Optimism" class="max-w-max mx-auto w-60v xsm:w-30v" src={zonic1}></img></a>

            </div>
            </h4>

            </div>

            </div>

        </section>


        <section id='vision'>

          <div class='xsm:flex text-center h-70v max-w-max mx-auto'>
            
            <div class='max-h-max my-auto'>
              <span class='absolute left-[10%]'>
                <img alt="Tickled Picklez NFT Optimism" class="w-50v xsm:w-20v" src={rocketpicklez}></img>
              </span>

              <h4 id="stroke" class='z-2 leading-snug text-white rounded-2xl tracking-wider w-[90%] max-w-max mx-auto xsm:w-[45vw] max-h-max my-auto font-bold'>
                <span id="highlight1" class='bg-pickle-blue border border-black rounded-3xl px-5'> Rocket Picklez </span>
                <span class='text-pickle-red'> Hodl</span> at least <span class='text-pickle-green'>1 Pickle </span>
                and gain access to the game!
              </h4>

            </div>
 
          </div>

          <div class='xsm:flex text-center h-70v max-w-max mx-auto'>
            
            <div class='max-h-max my-auto'>
              <span class='absolute left-[20%]'>
                <img alt="Tickled Picklez NFT Optimism" class="w-50v xsm:w-20v" src={tornado}></img>
              </span>

              <h4 id="stroke" class='z-2 leading-snug text-white rounded-2xl tracking-wider w-[60%] max-w-max mx-auto xsm:w-[35vw] max-h-max my-auto font-bold'>
                Do <span id="highlight1" class='text-pickle-blue'> NOT</span> miss the UPCOMING
                <span class='text-pickle-red' id="highlight1"> L2</span> STORM
              </h4>

            </div>

            <div class='my-auto'>
              <img alt="Tickled Picklez NFT Optimism" class="w-[90%] xsm:w-50v max-w-max mx-auto relative rounded-3xl border-gray-600 border z-0" id="imgtrf" src={l2storm}></img>
            </div>
 
          </div>
        
          <div class="max-w-max mx-auto animate-bounce">
            <img alt="Tickled Picklez NFT Optimism" class="w-15v xsm:w-5v" src={arrow}></img>
          </div>

          
        </section>

       

        <section id='community'>

          <div class=''>
              <div class='max-h-max my-auto z-1'>
                        <h4 id="stroke" class='xmb:h-[12vh] text-white tracking-widest text-center font-bold xmb:mb-0 mb-4'>        
                          LOVELY COMMUNITY
                        </h4>
              </div>
        
              <div class='flex'>

                <span class='absolute'>
                  <img alt="Tickled Picklez NFT Optimism" id="pickle3" class="w-13v"src={pickle3}></img>
                </span>

              </div>

              <div class='z-0 xsm:flex max-w-max gap-12 mx-auto'>
                      
                  <img alt="Tickled Picklez NFT Optimism" class="xsm:w-30v w-[90%] max-w-max mx-auto rounded-3xl border-gray-600 border z-0" id="imgtrf" src={attention}></img>
                  <img alt="Tickled Picklez NFT Optimism" class="xsm:w-40v xsm:h-60v w-[90%] max-w-max mx-auto rounded-3xl border-gray-600 border z-0 mt-12 xsm:mt-0" id="imgtrf" src={lovers}></img>
             
                  
              </div>
            
            </div>

            <div class='mt-12'>
                  <div class="max-w-max mx-auto animate-bounce">
                    <img alt="Tickled Picklez NFT Optimism" class="w-15v xsm:w-5v" src={arrow}></img>
                  </div>
            </div>
          

          <div class=''>
              <div class='max-h-max my-auto z-1'>
              <br/><br/><br/><br/>

                <h4 id="stroke" class='text-white tracking-widest text-center font-bold'>        
                  JOIN THE PICKLE GANG
                </h4>

              <div class="max-w-max mx-auto">

                <a href='https://guild.xyz/tickledpicklez'>
                  <p id="joinguild" class='text-white bg-pickle-purple font-bold

                  hover:bg-pickle-redpurple
                  p-3
                  max-w-max w-auto rounded-2xl
                  border-black tracking-wide'>go to GUILD.XYZ</p>
                </a>

              </div>
              
              </div>
        
              <div class='flex'>

              </div>

              <div class='z-0 max-w-max xsm:flex gap-12 mx-auto mt-12'>
                      
                  <img alt="Tickled Picklez NFT Optimism" class="xsm:w-40v w-[90%] max-w-max mx-auto rounded-3xl border-gray-600 border z-0 mb-12 xsm:mb-0" id="imgtrf" src={guild}></img>
                  <img alt="Tickled Picklez NFT Optimism" class="xsm:w-50v w-[90%] max-w-max mx-auto rounded-3xl border-gray-600 border z-0" id="imgtrf" src={room}></img>
                  
              </div>
            
            </div>

            <div class=''>

                  <div class='max-h-max my-auto z-1  w-[70vw] max-w-max mx-auto'>
                    <h5 id="stroke" class='text-white tracking-widest text-center mt-4 font-bold'>        
                    Be Hodler and get Access to <span class='text-pickle-blue'>Private Rooms</span>
                    </h5> 
                  </div>


                  

                  <div class='z-0 max-w-max flex gap-12 mx-auto mt-12'>
                    
                      
                  </div>

  

          </div>
          </section>

          <section id='team' class=''>

          
          <p><br/></p>
          <div class='font-bold'>
              <div class='max-h-max my-auto z-1'>
                        <h4 id="stroke" class='py-4 lg:py-6 text-white tracking-widest text-center font-bold'>        
                          TEAM
                        </h4>
              </div>
  
              <div class='max-w-max mx-auto gap-12'>

                  <div id="row-1" class="xsm1:flex gap-6">
                      <div className='mb-6 xsm:mb-6'>
                          
                          <img alt="Tickled Picklez NFT Optimism" class="max-w-max mx-auto w-50v xsm:w-15v rounded-3xl border-gray-600 border" id="imgtrf" src={akerems}></img>
                          <h6 id="stroke" class='text-white tracking-widest text-center'>akerems</h6>
                          <h6 id="stroke" class='tracking-widest text-center text-pickle-yellow'>Founder</h6>
                          <a href='https://twitter.com/akerems7' target='_blank' rel="noreferrer" ><img id="litrf" alt="" class="w-10v xsm:w-3v max-w-max mx-auto" src={twitter}></img></a>
      
                      </div>
      
                      <div className='mb-6 xsm:mb-6'>
                              
                          <img alt="Tickled Picklez NFT Optimism" class="max-w-max mx-auto w-50v xsm:w-15v rounded-3xl border-gray-600 border z-0" id="imgtrf" src={lucas}></img>
                          <h6 id="stroke" class='text-white tracking-widest text-center'>Lucas.OP</h6>
                          <h6 id="stroke" class='tracking-widest text-center text-pickle-purple'>SM manager <br/> </h6>
                          <a href='https://twitter.com/x2k2_' target='_blank' rel="noreferrer" ><img alt="Tickled Picklez NFT Optimism" id="litrf" class="w-10v xsm:w-3v max-w-max mx-auto" src={twitter}></img></a>
      
                          
                      </div>
      
                      <div className='mb-6 xsm:mb-6'>
                              
                              <img alt="Tickled Picklez NFT Optimism" class="max-w-max mx-auto w-50v xsm:w-15v rounded-3xl border-gray-600 border z-0" id="imgtrf" src={peculiar}></img>
                              <h6 id="stroke" class='text-white tracking-widest text-center'>peculiar</h6>
                              <h6 id="stroke" class='tracking-widest text-center text-pickle-blue'>CM<br/> </h6>
          
                              
                      </div>
                  </div>
              
                  <div id="row-2" class="xsm1:flex gap-6">     
                        <div className='mb-6 xsm:mb-6'>
                            
                            <img alt="Tickled Picklez NFT Optimism" class="max-w-max mx-auto w-50v xsm:w-15v rounded-3xl border-gray-600 border z-0" id="imgtrf" src={chaku}></img>
                            <h6 id="stroke" class='text-white tracking-widest text-center'>chaku</h6>
                            <h6 id="stroke" class='tracking-widest text-center text-pickle-blue'>Mod<br/> </h6>
        
                            
                        </div>
        
                        <div className='mb-6 xsm:mb-6'>
                                
                            <img alt="Tickled Picklez NFT Optimism" class="max-w-max mx-auto w-50v xsm:w-15v rounded-3xl border-gray-600 border z-0" id="imgtrf" src={mizaru}></img>
                            <h6 id="stroke" class='text-white tracking-widest text-center'>mizaru</h6>
                            <h6 id="stroke" class='tracking-widest text-center text-pickle-green'>Content</h6>
                            <a href='https://twitter.com/m1zaru' target='_blank' rel="noreferrer" ><img alt="Tickled Picklez NFT Optimism" id="litrf" class="w-10v xsm:w-3v max-w-max mx-auto" src={twitter}></img></a>
        
                        </div>
                  </div>


              </div>

          </div>
          </section>





      <footer>
        <div class='text-center font-normal'>Tickled Picklez 2023</div>
      </footer>



    </main>
  )
}
